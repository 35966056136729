.infoCardDeck {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  justify-content: center;
}

.infoCard {
  text-align: center;
  font-size: 20px;
}

.closeButton {
  position: absolute;
  right: 0;
  top: 0;
  color: #000 !important;
}

.temperature {
  font-size: 80px;
  font-weight: bold;
}

.unit {
  font-size: 45px;
}
