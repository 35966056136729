.searchBar {
  max-width: 50%;
  margin: auto;
}

.radioContainer {
  width: fit-content;
  margin-right: 24vw;
  margin-left: auto;
}

.alertMsg {
  width: 50%;
  margin: auto;
}
